<template>
	<div>
		<div class="hide show@max-tablet">
			<div class="f-tab-section__mobile gr gr--wrap">
				<div class="col col--12@max-tablet">
<!--					<VMultiselect class="multiselect-countries"></VMultiselect>-->

				</div>
				<div class="custom-multiselect">
					<div class="custom-multiselect__input" @click="selectCountry = !selectCountry">
						<div class="custom-multiselect__input__content" v-if="ewgFilters.country">
							<div><img :src="require(`@/assets/imgs/flags/${ewgFilters.country.imageSlug}.png`)" alt=""></div>
							<VText size="small" weight="bold">{{ ewgFilters.country.name }}</VText>
						</div>
						<div class="custom-multiselect__input__arrow">
							<img src="@/assets/imgs/icons/icon-down-arrow.svg" alt="">
						</div>
					</div>
					<div class="custom-multiselect__menu" v-if="selectCountry">
						<div v-for="country in countries"
							 :key="country.slug"
							 @click="ewgFilters.country = country;selectCountry = !selectCountry"
							 class="custom-multiselect__menu__item">
							<div>
								<img
									:src="require(`@/assets/imgs/flags/${country.imageSlug}.png`)"
									:alt="country.name"
								>
							</div>
							<div class="p p--small p--bold">{{ country.name }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class=" hide show@tablet">
			<div class="f-tab-section__tab">
				<div
					v-for="country in countries"
					:key="country.slug"
					:class="{ 'active': isCountryActive(country) }"
					class="f-tab-section__tab__item"
                    @click="ewgFilters.country = country"
				>
					<div class="f-tab-section__tab__item__image">
						<img
							:src="require(`@/assets/imgs/flags/${country.imageSlug}.png`)"
							:alt="country.name"
                            height="39"
						/>
					</div>
					<VText>{{ country.name }}</VText>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { FETCH_EWG_COUNTRIES } from "@/store/actions.type";
import { mapState } from 'vuex';

export default {
    name: 'Countries',
	data() {
    	return {
    		selectCountry: false,
		}
	},
    computed: {
        ...mapState({
            countries: state => state.ewg.countries,
            ewgFilters: state => state.ewg.ewgFilters,
        })
    },
    mounted() {
        this.fetchCountries();
    },
    methods: {
        async fetchCountries() {
            await this.$store.dispatch(FETCH_EWG_COUNTRIES);
        },
        isCountryActive(country) {
            return this.ewgFilters.country && this.ewgFilters.country.slug === country.slug;
        }
    }
};
</script>
<style scoped>
	.f-tab-section__mobile {
		margin-bottom: -40px;
		padding: 9px 15px;
	}
</style>
