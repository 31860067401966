<template>
    <div class="eng-tab-section">
        <div class="eng-tab-section__tab">
            <router-link
                :to="{name: 'systems-and-policies'}"
                tag="div"
				active-class="active"
                class="eng-tab-section__tab__item cursor-pointer"
            >
                <div class="subtitle subtitle--semibold">
                    People & policies
                </div>
            </router-link>
            <router-link
                :to="{name: 'engagement-windows'}"
                tag="div"
                active-class="active"
                class="eng-tab-section__tab__item cursor-pointer"
            >
            <div class="subtitle subtitle--semibold">
                Engagement windows
            </div>
        </router-link>
        </div>
        <div id="tab-content" class="eng-tab-section__content">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Tabs'
};
</script>
