<template>
	<div class="f-tab-section__content__card">
		<div v-if="activeCountry">
			<div class="f-tab-section__content__card__header">
				<img
					:src="require(`@/assets/imgs/flags/${activeCountry.imageSlug}.png`)"
					:alt="activeCountry.name"
					class="desktop-only"
				/>
				<div>
					<div class="subtitle subtitle--big">
						{{ activeCountry.name }} Overview
					</div>
					<VText size="big">
						<b>Next election:</b> {{ activeCountry.nextElectionDate }}
					</VText>
				</div>
			</div>

			<VText size="small" v-html="activeCountry.description"></VText>
			<VLink
				size="small"
				@click.prevent.stop="viewOrganisation('institution')"
			>
				View institutions
			</VLink>
			<VLink size="small" @click.prevent.stop="viewOrganisation('policy')">
				View policies
			</VLink>
			<VLink size="small" @click.prevent.stop="viewOrganisation('programme')">
				View programmes
			</VLink>
		</div>
		<div
			class="f-tab-section__content__highlight"
			v-if="
				activeCountry &&
					(parliamentHasParties || activeCountry.parliamentDescription)
			"
		>
			<div class="grid" v-if="parliamentHasParties">
				<div id="demo-target" class="cell alpha"></div>
				<textarea id="demo-data">
                    {{ activeCountry.parliament }}
                </textarea
				>
			</div>
			<div class="legend" v-if="parliamentHasParties">
				<div
					class="legend-container"
					v-for="(partie, partieName) in activeCountry.parliament"
					:key="partie.slug"
				>
					<div
						class="flag"
						:style="partie.colour ? { backgroundColor: partie.colour } : {}"
					>
						<img
							:src="getImage(partie.image)"
							:alt="partie.name"
							v-if="partie.image"
						/>
					</div>
					<VText size="smaller" weight="semibold">
						{{ partieName }} [{{ partie.seats }}]
					</VText>
				</div>
			</div>
			<div
				class="hr-striped"
				v-if="parliamentHasParties && activeCountry.parliamentDescription"
			></div>
			<p
				v-if="activeCountry.parliamentDescription"
        v-html="activeCountry.parliamentDescription"
        class="p p--smaller p--semibold"
      ></p>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { SET_EW_FILTERS } from '@/store/mutations.type';

export default {
	name: 'CountryOverview',
	computed: {
		...mapGetters(['snpFilters']),
		...mapState({
			countries: state => state.ewg.countries,
			activeCountry: state => state.ewg.ewgFilters.country,
			ewgFilters: state => state.ewg.ewgFilters,
			sectors: state => state.app.sectors
		}),
		ewFilters: {
			get() {
				return this.$store.state.engagementWindows.ewFilters;
			},
			set(value) {
				this.$store.commit(SET_EW_FILTERS, value);
			}
		},
		parliamentHasParties() {
			return Object.values(this.activeCountry.parliament).length;
		}
	},
	watch: {
		countries: {
			handler: 'setCountry',
			immediate: true,
			deep: true
		},
		activeCountry: {
			handler: 'setData',
			immediate: true
		}
	},
	methods: {
		setData(country) {
			if (!country) return;
			this.setCountryQueryParam(country);
			this.setCountryParliamentChart();
		},
		setCountryParliamentChart() {
			this.$nextTick(() => {
				const currentScript = document.querySelector(
					"script[src='/scripts/parliament.min.js']"
				);
				const currentChart = document.querySelector('#demo-target > svg');

				if (currentScript) currentScript.remove();
				if (currentChart) currentChart.remove();

				const parliamentScript = document.createElement('script');
				parliamentScript.setAttribute('src', '/scripts/parliament.min.js');
				document.body.appendChild(parliamentScript);
			});
		},
		setCountryQueryParam(country) {
			const params = { ...this.$route.query, ...{ country: country.slug } };
			this.$router
				.replace({ name: this.$route.name, query: params })
				.catch(err => err);
		},
		setCountry(countries) {
			if (this.$route.query.country) {
				this.ewgFilters.country = countries.find(
					country => country.slug === this.$route.query.country
				);
				return;
			}

			this.ewgFilters.country = countries[0];
		},
		viewOrganisation(type) {
			if (this.$route.name === 'systems-and-policies') {
				this.snpFilters.type = type;
				return;
			}

			const query = {
				country: this.activeCountry.slug,
				type: type
			};

			this.$router
				.replace({ name: 'systems-and-policies', query })
				.catch(err => err);
		}
	}
};
</script>

<style>
.grid {
	width: 100%;
}

#demo-data {
	visibility: hidden;
	height: 0;
}

.seatNumber {
	fill: #333333;
	font-size: 3px !important;
	font-family: Muli !important;
}
</style>
