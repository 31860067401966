<template>
	<Tab>
		<template #title>The policy space</template>
		<template #subtitle> Select country</template>
		<template #content>
			<div class="tab-section__content__main tab-section__content__main--ewg">
				<div class="f-tab-section">
					<Countries />

					<div class="f-tab-section__content">
						<CountryOverview />

						<Tabs />
					</div>
				</div>
			</div>
		</template>
	</Tab>
</template>

<script>
import Tabs from '@/pages/LandingPage/sections/EngagingWithGovernment/Tabs';
import Countries from '@/pages/LandingPage/sections/EngagingWithGovernment/Countries';
import CountryOverview from '@/pages/LandingPage/sections/EngagingWithGovernment/CountryOverview';

export default {
	name: 'EngagingWithGovernment',
	components: {
		Tabs,
		Countries,
		CountryOverview
    }
};
</script>
